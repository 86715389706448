import {Component, Inject, Renderer2} from '@angular/core';
import {AppService} from '@app/core/services/app.service';
import {Theme} from '@app/core/model/Config';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-settings',
  templateUrl: './setting.component.html'
})
export class SettingComponent {
  public themes: Theme[] = [];

  constructor(@Inject(DOCUMENT) private document, private renderer: Renderer2, private appService: AppService) {
    this.themes = this.appService.getThemes();
  }

  updateTheme(theme: Theme): void {
    const userConfig = this.appService.getUserConfig();
    userConfig.theme = theme;
    this.appService.updateConfig(userConfig);
  }

  updateSkin(skin: string): void {
    const userConfig = this.appService.getUserConfig();
    userConfig.skin = skin;
    this.appService.updateConfig(userConfig);
  }
}
