import {Component, Input, Inject, NgZone, PLATFORM_ID, AfterViewInit, OnDestroy} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {ClockHand} from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_material from '@amcharts/amcharts4/themes/material';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {Gauge, Hand} from '@app/core/model/PhasorDiagram';

@Component({
  selector: 'app-gauge',
  templateUrl: './gauge.component.html'
})
export class GaugeComponent implements OnDestroy {
  @Input() gauge: Gauge;

  private chart: am4charts.GaugeChart;

  private red1Hand: ClockHand;
  private red2Hand: ClockHand;
  private yellow1Hand: ClockHand;
  private yellow2Hand: ClockHand;
  private blue1Hand: ClockHand;
  private blue2Hand: ClockHand;

  private indicator;

  constructor(@Inject(PLATFORM_ID) private platformId, private zone: NgZone, private http: HttpClient) {
  }

  showIndicator(): void {
    this.indicator = this.chart.tooltipContainer.createChild(am4core.Container);
    this.indicator.background.fill = am4core.color('#fff');
    this.indicator.width = am4core.percent(100);
    this.indicator.height = am4core.percent(100);

    const indicatorLabel = this.indicator.createChild(am4core.Label);
    indicatorLabel.text = 'Loading data...';
    indicatorLabel.align = 'center';
    indicatorLabel.valign = 'middle';
    indicatorLabel.fontSize = '24';
    indicatorLabel.dy = 50;

    this.indicator.show();
  }

  // Run the function only in the browser
  browserOnly(f: () => void): void {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  @Input()
  set setSomeInputValue(gauge: Gauge) {
    this.browserOnly(() => {
      if (this.chart) {
        this.chart.dispose();
      }

      am4core.useTheme(am4themes_material);
      am4core.useTheme(am4themes_animated);

      this.chart = am4core.create('chartdiv', am4charts.GaugeChart);
      this.chart.hiddenState.properties.opacity = 0;

      this.chart.events.on('ready', _ => {
        if (this.indicator) {
          this.indicator.hide();
        }
      });

      this.chart.startAngle = 0;
      this.chart.endAngle = 360;
      const axis = this.createAxis(1, 360, -360, 0, '#1a0707');

      const redColor = am4core.color('#ad2d2d');
      const yellowColor = am4core.color('#dede85');
      const blueColor = am4core.color('#2d49ad');

      this.red1Hand = this.createHand(axis, 90, 8, redColor);
      this.red2Hand = this.createHand(axis, 85, 4, redColor);
      this.yellow1Hand = this.createHand(axis, 90, 8, yellowColor);
      this.yellow2Hand = this.createHand(axis, 85, 4, yellowColor);
      this.blue1Hand = this.createHand(axis, 90, 8, blueColor);
      this.blue2Hand = this.createHand(axis, 85, 4, blueColor);

      this.setHandValue(this.red1Hand, gauge.red1);
      this.setHandValue(this.red2Hand, gauge.red2);
      this.setHandValue(this.yellow1Hand, gauge.yellow1);
      this.setHandValue(this.yellow2Hand, gauge.yellow2);
      this.setHandValue(this.blue1Hand, gauge.blue1);
      this.setHandValue(this.blue2Hand, gauge.blue2);
    });
  }

  setHandValue(hand: ClockHand, value: Hand): void {
    hand.showValue(value.angle, 0, am4core.ease.cubicOut);
    hand.tooltipHTML = value.angle + ' Deg<br/>' + value.value;
  }

  createHand(axis, size, width, color): ClockHand {
    const hand: ClockHand = this.chart.hands.push(new am4charts.ClockHand());
    hand.fill = color;
    hand.stroke = color;
    hand.axis = axis;
    hand.pin.disabled = true;
    hand.startWidth = width;
    hand.endWidth = width;
    hand.radius = am4core.percent(0);
    hand.innerRadius = am4core.percent(size);
    hand.tooltipPosition = 'pointer';
    hand.value = 0;
    return hand;
  }

  createAxis(min, max, start, end, color): void {
    // @ts-ignore
    const axis = this.chart.xAxes.push(new am4charts.ValueAxis());
    // @ts-ignore
    axis.min = min;
    // @ts-ignore
    axis.max = max;
    // @ts-ignore
    axis.fill = color;
    // @ts-ignore
    axis.strictMinMax = true;
    axis.renderer.useChartAngles = true;
    axis.tooltip.label.tapToActivate = true;
    axis.renderer.startAngle = start;
    axis.renderer.endAngle = end;
    axis.renderer.minGridDistance = 25;

    axis.renderer.line.strokeOpacity = 1;
    axis.renderer.line.strokeWidth = 10;
    axis.renderer.line.stroke = am4core.color(color);

    const ds = new am4core.DropShadowFilter();
    ds.blur = 3;
    ds.opacity = 0.5;
    axis.filters.push(ds);

    axis.renderer.ticks.template.disabled = false;
    axis.renderer.ticks.template.stroke = am4core.color(color);
    axis.renderer.ticks.template.strokeOpacity = 1;
    axis.renderer.grid.template.disabled = true;
    axis.renderer.ticks.template.length = 10;

    // @ts-ignore
    return axis;
  }

  ngOnDestroy(): void {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }
}
