<table class="table table-bordered">
  <tr *ngIf="showHeader">
    <td>
      <table class="table table-responsive borderless-table">
        <tr>
          <td class="bill-heading">Customer:</td>
          <td>{{bill.customer}}</td>
        </tr>
        <tr>
          <td class="bill-heading">Tarrif:</td>
          <td>{{bill.tariff?.title || 'n/a'}}</td>
        </tr>
        <tr>
          <td class="bill-heading">Period:</td>
          <td>
            From: {{bill.period.from}}<br/>
            To:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{bill.period.to}}
          </td>
        </tr>
      </table>
    </td>
    <td *ngIf="bill.meterTotal" >
      <table class="table table-responsive borderless-table">
        <tr>
          <td class="bill-heading">Meter Totals:</td>
          <td>{{bill.meterTotal}}</td>
        </tr>
        <tr *ngFor="let meter of bill.meters">
          <td class="bill-heading">Meter ({{meter.serialNumber}}):</td>
          <td><b>Start reading:</b> {{meter.startReading}}<br/>
            <b>End reading:</b> {{meter.endReading}}</td>
        </tr>
      </table>
    </td>
  </tr>
  <tr *ngIf="bill.tariffs.length && showTariff">
    <td colspan="2">
      <table class="table table-responsive borderless-table">
        <tr>
          <td class="bill-heading" colspan="2">Compare Tariffs</td>
        </tr>
        <tr>
          <td style="width: 50%;"><b>Tariff A: </b> {{bill.tariff.title}}</td>
          <td>
            <b>Tariff B: </b>
            <select (change)="onTariffSelected($event)">
              <option value="">Choose Tariff to compare with</option>
              <option *ngFor="let tariff of bill.tariffs" [value]="tariff.id">
                {{tariff.title}}
              </option>
            </select>
          </td>
        </tr>
      </table>
    </td>
  </tr>
</table>
<p></p>
<table class="table table-bordered" *ngIf="showBill">
  <thead class="thead-themed">
  <tr *ngIf="!showHeader">
    <td colspan="5"><b>Tariff:</b> {{bill.tariff.title}}</td>
  </tr>
  <tr class="bill-heading">
    <th style="width: 20%">TARIFF</th>
    <th style="width: 41%">DESCRIPTION</th>
    <th style="width: 13%">UNITS</th>
    <th style="width: 13%">RATE[R]</th>
    <th style="width: 13%">AMOUNT</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let item of bill.items">
    <td>{{item.tariff}}</td>
    <td>{{item.description}}</td>
    <td>{{item.units}}</td>
    <td>{{item.rate}}</td>
    <td>{{item.amount}}</td>
  </tr>
  <tr class="noBorder">
    <td colspan="5"><p></p></td>
  </tr>
  <tr class="noBorder">
    <td></td>
    <td></td>
    <td></td>
    <td colspan="2">
      <table style="width: 100%" class="table border-table">
        <tr>
          <td class="bill-heading" style="width: 60%">Total before VAT:</td>
          <td>{{bill.subTotal}}</td>
        </tr>
        <tr>
          <td class="bill-heading">VAT (15%):</td>
          <td>{{bill.vatAmount}}</td>
        </tr>
        <tr>
          <td class="bill-heading">Total:</td>
          <td>{{bill.total}}</td>
        </tr>
      </table>
    </td>
  </tr>
  </tbody>
</table>
