'use strict';

export const DATE_FORMAT_SHOW = 'MMMM D, YYYY';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const PATH_AUTH = '/auth/login';


export const storageKeyMeterAccounts = 'meterAccountFolders';
export const storageKeySelectedMeter = 'meterAccountSelectedFolder';
export const storageKeyUserConfig = 'userConfig';
