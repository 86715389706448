import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {MeterAccount, MeterFolder} from '@app/core/model/Folder';
import {AppService} from '@app/core/services/app.service';
import {tap} from 'rxjs/operators';
import {storageKeyMeterAccounts, storageKeySelectedMeter} from '@app/core/helpers/app.const';

@Injectable({providedIn: 'root'})
export class FoldersService {

  constructor(private http: HttpClient, private applicationService: AppService) {
  }

  updateSelectedMeter(meterAccount: MeterAccount): void {
    this.applicationService.updateSelectedMeter(meterAccount);
  }

  getFolders(): Observable<MeterFolder> {
    const folders = JSON.parse(localStorage.getItem(storageKeyMeterAccounts));
    if (folders) {
      console.log('Getting folders from local storage....');
      return new BehaviorSubject<MeterFolder>(
        new MeterFolder(
          JSON.parse(localStorage.getItem(storageKeySelectedMeter)),
          folders
        )
      ).asObservable();
    } else {

      this.applicationService.postMessage(
        'Please Wait',
        'We are loading meter accounts and configurations'
      );

      console.log('Getting folders from server....');
      return this.http.get<MeterFolder>(`${environment.apiUrl}/user/folders`).pipe(
        tap(meterFolder => {
          console.log('folders available...save them to local storage....');
          localStorage.setItem(storageKeyMeterAccounts, JSON.stringify(meterFolder.folder));
          localStorage.setItem(storageKeySelectedMeter, JSON.stringify(meterFolder.selectedMeterAccount));
          this.applicationService.dismissMessage();
        })
      );
    }
  }
}
