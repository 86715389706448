import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ProvisionalBill} from '@app/core/model/Usage';

@Component({
  selector: 'app-bill',
  styleUrls: ['./bill.component.css'],
  templateUrl: './bill.component.html'
})
export class BillComponent {
  @Input() bill: ProvisionalBill;
  @Input() showHeader: boolean;
  @Input() showTariff: boolean;
  @Input() showBill: boolean;

  @Output() navOut = new EventEmitter();

  onTariffSelected(event): void {
    this.navOut.emit(event.target.value);
  }
}

