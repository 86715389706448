import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Saving} from '@app/core/model/Usage';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {DesiredPfService} from '@app/core/services/desired-pf.service';

@Component({
  selector: 'app-saving',
  templateUrl: './saving.component.html'
})
export class SavingComponent implements OnInit {
  @Output() navOut = new EventEmitter();

  @Input() saving: Saving;
  @Input() showUpdateOption: boolean;
  @Input() scadaMeterId: number;

  desiredPfForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private desiredPfService: DesiredPfService) {
  }

  ngOnInit(): void {
    this.desiredPfForm = this.formBuilder.group({
      desiredPf: ['', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get form(): { [p: string]: AbstractControl } {
    return this.desiredPfForm.controls;
  }

  onSubmit(): void {
    if (this.desiredPfForm.invalid) {
      return;
    }

    this.desiredPfService.setDesiredPf(this.scadaMeterId, this.form.desiredPf.value)
      .pipe(first())
      .subscribe(data => {
          this.navOut.emit(true);
        },
        error => {
        });
  }
}

