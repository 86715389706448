import {Graph} from '@app/core/model/Usage';
import {Component, Input, Inject, NgZone, PLATFORM_ID, AfterViewInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_dark from '@amcharts/amcharts4/themes/dark';
import am4themes_material from '@amcharts/amcharts4/themes/material';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {GraphConfig} from '@app/shared/component/graph/GraphConfig';
import {ColoredSample} from '@app/shared/component/graph/Sample';
import {GraphDateRange} from '@app/core/model/GraphDateRange';
import {AppService} from '@app/core/services/app.service';
import {Container} from '@amcharts/amcharts4/core';

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html'
})
export class GraphComponent implements AfterViewInit, OnDestroy {
  @Output() dateRangeChanged = new EventEmitter();
  @Output() resetGraph = new EventEmitter();

  @Input() graph: Graph;
  @Input() graphConfig: GraphConfig;
  private chart: am4charts.XYChart;

  private defaultColor = am4core.color('#22BB22');
  private defaultWaterMeterColor = am4core.color('#0040FF');

  constructor(@Inject(PLATFORM_ID) private platformId, private zone: NgZone, private appService: AppService) {
  }

  // Run the function only in the browser
  browserOnly(f: () => void): void {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngAfterViewInit(): void {

    this.browserOnly(() => {
      if (this.appService.getUserConfig().skin === 'mod-skin-dark') {
        am4core.useTheme(am4themes_dark);
      } else {
        am4core.useTheme(am4themes_material);
      }

      am4core.useTheme(am4themes_animated);

      const result = this.parseSamples(this.graph);

      const chart = am4core.create('chart', am4charts.XYChart);
      this.chart = chart;
      // chart.padding(0, 15, 0, 15);
      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.menu.align = 'left';
      chart.exporting.menu.verticalAlign = 'top';
      chart.exporting.dateFormat = 'YYYY-MM-DD HH:mm:ss';
      chart.data = result;
      chart.leftAxesContainer.layout = 'vertical';
      chart.bottomAxesContainer.reverseOrder = true;

      chart.zoomOutButton.events.on('hit', () => {
        this.resetGraph.emit(true);
      });

      const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.grid.template.location = 0;
      dateAxis.renderer.ticks.template.length = 8;
      dateAxis.renderer.ticks.template.strokeOpacity = 0.1;
      dateAxis.renderer.grid.template.disabled = true;
      dateAxis.renderer.ticks.template.disabled = false;
      dateAxis.renderer.ticks.template.strokeOpacity = 0.2;
      dateAxis.renderer.minLabelPosition = 0.01;
      dateAxis.renderer.maxLabelPosition = 0.99;
      dateAxis.keepSelection = true;

      if (this.graph.grouped) {
        dateAxis.groupData = true;
        dateAxis.minZoomCount = 5;
      } else {
        dateAxis.groupData = false;
        dateAxis.minZoomCount = 15;

        const indicator = chart.tooltipContainer.createChild(am4core.Container);
        this.showIndicator(indicator);

        chart.events.on('ready', () => {
          indicator.hide();
        });
      }

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = false;
      valueAxis.zIndex = 1;
      valueAxis.renderer.baseGrid.disabled = true;
      valueAxis.height = am4core.percent(100);

      valueAxis.renderer.gridContainer.background.fill = am4core.color('#000000');
      valueAxis.renderer.gridContainer.background.fillOpacity = 0.05;
      valueAxis.renderer.inside = true;
      valueAxis.renderer.labels.template.verticalCenter = 'bottom';
      valueAxis.renderer.labels.template.padding(2, 2, 2, 2);
      valueAxis.renderer.fontSize = '0.8em';

      const series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.dateX = 'date';

      series.sequencedInterpolation = false;
      series.columns.template.strokeWidth = 0;
      series.tooltip.pointerOrientation = 'vertical';
      series.columns.template.column.cornerRadiusTopLeft = 10;
      series.columns.template.column.cornerRadiusTopRight = 10;
      series.columns.template.propertyFields.fill = 'hexColor';
      series.columns.template.column.fillOpacity = 0.8;

      if (this.ifElectricity(this.graph.type)) {
        series.dataFields.valueY = 'kwh';
        series.name = 'kw';
        series.stroke = this.defaultColor;
        series.fill = this.defaultColor;
        series.legendSettings.valueText = '{valueY}';
        series.columns.template.tooltipText = 'kw: {valueY}';

        const lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.name = 'KVA';
        lineSeries.dataFields.valueY = 'kva';
        lineSeries.dataFields.dateX = 'date';
        lineSeries.stroke = am4core.color('#FF0000');
        lineSeries.strokeWidth = 1;
        lineSeries.propertyFields.strokeDasharray = 'lineDash';
        lineSeries.tooltip.label.tapToActivate = true;
        lineSeries.tooltip.label.textAlign = 'middle';
        const bullet = lineSeries.bullets.push(new am4charts.Bullet());
        bullet.fill = am4core.color('#FF0000'); // tooltips grab fill from parent by default
        /*todo: https://www.amcharts.com/docs/v4/concepts/data/*/
        bullet.tooltipText = 'kVA: {valueY} {dummyData}';

        chart.legend = new am4charts.Legend();
      } else {
        series.stroke = am4core.color('#8f94c1');
        series.dataFields.valueY = 'kl';
        series.name = 'kl';
        series.columns.template.tooltipText = 'kl: {valueY}';
      }

      if (this.graphConfig.showRange) {
        const range = valueAxis.axisRanges.push(new am4charts.ValueAxisDataItem());
        range.grid.stroke = chart.colors.getIndex(0);
        range.grid.strokeOpacity = 10;
        range.bullet = new am4core.ResizeButton();
        // @ts-ignore
        range.bullet.background.fill = chart.colors.getIndex(0);
        // @ts-ignore
        range.bullet.minY = 0;
        // tslint:disable-next-line:only-arrow-functions typedef
        range.bullet.adapter.add('minX', function(minX, target) {
          target.maxY = chart.plotContainer.maxHeight;
          target.maxX = chart.plotContainer.maxWidth;
          return 0;
        });
        // tslint:disable-next-line:only-arrow-functions typedef
        range.bullet.events.on('dragged', function() {
          range.value = valueAxis.yToValue(range.bullet.pixelY);
        });

        range.value = chart.plotContainer.maxHeight / 2;
      }

      chart.cursor = new am4charts.XYCursor();

      const scrollbarX = new am4charts.XYChartScrollbar();
      scrollbarX.series.push(series);
      scrollbarX.marginBottom = 10;
      scrollbarX.scrollbarChart.xAxes.getIndex(0).minHeight = undefined;
      chart.scrollbarX = scrollbarX;

      dateAxis.events.on('startendchanged', (ev) => {
        const start = new Date(ev.target.minZoomed);
        const end = new Date(ev.target.maxZoomed);
        this.dateRangeChanged.emit(new GraphDateRange(start, end));
      }, this);
    });
  }

  showIndicator(indicator: Container): void {
    indicator.width = am4core.percent(100);
    indicator.height = am4core.percent(100);

    const indicatorLabel = indicator.createChild(am4core.Label);
    indicatorLabel.text = 'Loading graph........';
    indicatorLabel.align = 'center';
    indicatorLabel.valign = 'top';
    indicatorLabel.fontSize = 16;
    indicatorLabel.dy = 10;
  }

  ngOnDestroy(): void {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

  ifElectricity(type: string): boolean {
    return type === 'ELECTRICITY';
  }

  parseSamples(graph: Graph): ColoredSample[] {
    const result: ColoredSample[] = [];
    let hexColor;
    if (this.ifElectricity(this.graph.type)) {
      hexColor = this.defaultColor;
    } else {
      hexColor = this.defaultWaterMeterColor;
    }

    for (let i = 0; i < graph.samples.length; i++) {
      const value = graph.samples[i];
      if (this.ifElectricity(graph.type) && graph.tariffColor && graph.tariffColor[i]) {
        hexColor = am4core.color('#' + graph.tariffColor[i].hexColor);
      }
      result.push(new ColoredSample(
        new Date(value.date),
        value.kl,
        value.kva,
        value.kwh,
        value.pf,
        value.pkw,
        hexColor
      ));
    }
    return result;
  }
}
