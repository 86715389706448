import {NgModule} from '@angular/core';
import {FolderComponent} from '@app/shared/component/folder/folder.component';
import {SettingComponent} from '@app/shared/component/setting/setting.component';
import {LogoutComponent} from '@app/shared/component/logout/logout.component';
import {DatePickerComponent} from '@app/shared/component/date-picker/date-picker.component';
import {Daterangepicker} from 'ng2-daterangepicker';
import {SavingComponent} from '@app/shared/component/saving/saving.component';
import {BillComponent} from '@app/shared/component/bill/bill.component';
import {GraphComponent} from '@app/shared/component/graph/graph.component';
import {CommonModule, DatePipe} from '@angular/common';
import {LoadingComponent} from '@app/shared/component/loading/loading.component';
import {GaugeComponent} from '@app/shared/component/gauge/gauge.component';
import {ErrorMessageComponent} from '@app/shared/component/error-message/error-message.component';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  providers: [
    DatePipe
  ],
  imports: [
    Daterangepicker,
    ReactiveFormsModule,
    CommonModule,
  ],
  declarations: [
    FolderComponent,
    SavingComponent,
    GraphComponent,
    GaugeComponent,
    BillComponent,
    DatePickerComponent,
    ErrorMessageComponent,
    LogoutComponent,
    LoadingComponent,
    SettingComponent,
  ],
  exports: [
    FolderComponent,
    DatePickerComponent,
    LogoutComponent,
    SettingComponent,
    ErrorMessageComponent,
    LoadingComponent,
    SavingComponent,
    BillComponent,
    GraphComponent,
    GaugeComponent
  ]
})
export class ComponentModule {
}
