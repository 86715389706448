import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {MainLayoutComponent} from './main/main-layout.component';
import {RouterModule} from '@angular/router';
import {AuthLayoutComponent} from './auth/auth-layout.component';
import {UtilsModule} from '../utils/utils.module';
import {ComponentModule} from '@app/shared/component/component.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    UtilsModule,
    ComponentModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    UtilsModule,
    ComponentModule
  ],
  declarations: [
    MainLayoutComponent,
    AuthLayoutComponent,
  ]
})
export class LayoutModule {
}
