export class DateRange {
  public single: boolean;
  public start: string;
  public end: string;

  constructor(single: boolean, start: string, end: string) {
    this.single = single;
    this.start = start;
    this.end = end;
  }
}
