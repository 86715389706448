// to: type for icons and styles?
export class Message {
  header: string;
  description: string;

  public constructor(header: string, message: string) {
    this.header = header;
    this.description = message;
  }
}
