import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '@environments/environment';
import {User} from '../model/User';

@Injectable({providedIn: 'root'})
export class AuthService {
  private endPoint = '/user/authenticate';
  private storageKey = 'currentUser';
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  // store user details and jwt token in local storage to keep user logged in between page refreshes
  login(username: string, password: string): Observable<User> {
    const url = `${environment.apiUrl}${this.endPoint}`;
    const body = {username, password};

    return this.http.post<any>(url, body)
      .pipe(
        map(user => {
          localStorage.setItem(this.storageKey, JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  // remove user from local storage to log user out
  logout(): void {
    localStorage.clear();
    this.currentUserSubject.next(null);
    location.reload();
  }
}

