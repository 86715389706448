<!-- Todo: Use templates for nested html-->
<div class="panel-container show">
  <div class="panel-content">
    <div class="btn-group">

      <button *ngIf="!loading" class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
              aria-haspopup="false"
              aria-expanded="false" data-display="static">
        <i class="ni ni-energy mr-1"></i> {{selectedMeterAccount.title}}
      </button>

      <button *ngIf="loading" class="btn btn-danger dropdown-toggle" type="button" disabled>
        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        Loading...
      </button>

      <div class="dropdown-menu  no-transition-delay" *ngIf="parentFolder">
        <div class="dropdown-multilevel" *ngFor="let folder of parentFolder.folders">
          <!--Level 1-->
          <div class="dropdown-item"><i class="fal fa-folder"></i> {{folder.title}}</div>

          <div class="dropdown-menu no-transition-delay" *ngIf="folder.meterAccounts">
            <a (click)="onSelected(meterAccount)" class="dropdown-item"
               *ngFor="let meterAccount of folder.meterAccounts">
              <i class="ni ni-energy mr-1"></i> {{meterAccount.title}}
            </a>

            <div class="dropdown-multilevel" *ngFor="let folder of folder.folders">
              <div class="dropdown-item">{{folder.title}}</div>
              <div class="dropdown-menu no-transition-delay" *ngIf="folder.meterAccounts">
                <!--Level 2-->
                <a (click)="onSelected(meterAccount)" class="dropdown-item"
                   *ngFor="let meterAccount of folder.meterAccounts">
                  <i class="ni ni-energy mr-1"></i> {{meterAccount.title}}
                </a>
                <div class="dropdown-multilevel" *ngFor="let folder of folder.folders">
                  <div class="dropdown-item">{{folder.title}}</div>
                  <div class="dropdown-menu no-transition-delay" *ngIf="folder.meterAccounts">
                    <!--Level 3-->
                    <a (click)="onSelected(meterAccount)" class="dropdown-item"
                       *ngFor="let meterAccount of folder.meterAccounts">{{meterAccount.title}}</a>
                    <div class="dropdown-multilevel" *ngFor="let folder of folder.folders">
                      <div class="dropdown-item">{{folder.title}}</div>
                      <div class="dropdown-menu no-transition-delay" *ngIf="folder.meterAccounts">
                        <!--Level 4-->
                        <a (click)="onSelected(meterAccount)" href="#" class="dropdown-item"
                           *ngFor="let meterAccount of folder.meterAccounts">
                          <i class="ni ni-energy mr-1"></i> {{meterAccount.title}}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <a (click)="onSelected(meterAccount)" class="dropdown-item"
           *ngFor="let meterAccount of parentFolder.meterAccounts">
          <i class="ni ni-energy mr-1"></i> {{meterAccount.title}}
        </a>

      </div>
    </div>
  </div>
</div>
