import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BootstrapModule} from './bootstrap.module';
import {UtilsModule} from './utils/utils.module';
import {LayoutModule} from './layout/layout.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    LayoutModule,
    BootstrapModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    BootstrapModule,
    UtilsModule,
  ]
})
export class SharedModule {
}
