export class MeterFolder {
  selectedMeterAccount: MeterAccount;
  folder: Folder;

  public constructor(selectedMeterAccount: MeterAccount, folder: Folder) {
    this.selectedMeterAccount = selectedMeterAccount;
    this.folder = folder;
  }
}

export class Folder {
  title: string;
  meterAccounts: MeterAccount[];
  folders: Folder[];
}

export class MeterAccount {
  cid: number;
  scadaMeterId: number;
  meterAccountEid: number;
  title: string;
}
