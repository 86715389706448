<div id="saving" class="panel" *ngIf="saving">
  <div class="panel-hdr">
    <h2>Savings</h2>
    <div class="panel-toolbar">
      <button class="btn btn-panel waves-effect waves-themed" data-action="panel-fullscreen" data-toggle="tooltip"
              data-offset="0,10" data-original-title="Fullscreen"></button>
    </div>
  </div>
  <div class="panel-container show">
    <div class="panel-content">
      <div class="panel-tag">
        Possible savings with pf = <code>0.999</code>
      </div>

      <h2 class="d-flex justify-content-center">
        <button type="button" class="btn btn-success">
          <span class="fal fa-money-bill-wave"></span> {{saving.possibleSavings}}
        </button>
      </h2>

      <div class="d-none d-print-block">
      <div class="frame-wrap container-fluid d-flex justify-content-md-center align-items-center w-100">
        <span class="fal fa-money-bill-wave"></span> {{saving.possibleSavings}}
      </div>
      </div>

      <div class="row">&nbsp;</div>

      <div class="frame-wrap">
        <table class="table table-bordered table-sm">
          <thead>
          <tr>
            <th>CRITERIA</th>
            <th>UNITS</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Current Power Factor</td>
            <td>{{saving.currentPowerFactor}}</td>
          </tr>
          <tr>
            <td>Desired Power Factor</td>
            <td>{{saving.desiredPowerFactor}}</td>
          </tr>
          <tr>
            <td>Load KW</td>
            <td>{{saving.loadKW}}</td>
          </tr>
          <tr>
            <td>Highest KVA</td>
            <td>{{saving.highestKva}}</td>
          </tr>
          <tr>
            <td>KVAR required</td>
            <td>{{saving.kvarRequired || 'N/A'}}</td>
          </tr>
          <tr class="bg-gray-50">
            <td>Final kVA after capacitors</td>
            <td>{{saving.finalKvaAfterCapacitors || 'N/A'}}</td>
          </tr>
          <tr *ngIf="showUpdateOption">
            <td colspan="2">
              <form [formGroup]="desiredPfForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                  <div class="input-group">
                    <input type="text" formControlName="desiredPf" class="form-control"
                           placeholder="Set Desired power factor"
                           aria-label="Set Desired power factor" aria-describedby="button-addon5">
                    <div class="input-group-append">
                      <button class="btn btn-primary waves-effect waves-themed" type="submit" id="button-addon5"><i
                        class="fal fa-edit"></i></button>
                    </div>
                  </div>
                  <p class="help-block" style="text-align: center;"><i class="fal fa-info-circle"></i> Contact us to see
                    how you can achieve these savings.
                  </p>
                </div>
              </form>
            </td>
          </tr>
          </tbody>
        </table>
        <p></p>
        <p></p>
      </div>
    </div>
  </div>
</div>





