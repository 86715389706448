<div class="alert alert-danger alert-dismissible fade show" *ngIf="errorMessage">
  <div class="d-flex align-items-center">
    <div class="alert-icon width-8">
              <span class="icon-stack icon-stack-xl">
                  <i class="base-2 icon-stack-3x color-danger-400"></i>
                  <i class="base-10 text-white icon-stack-1x"></i>
                  <i class="ni md-profile color-danger-800 icon-stack-2x"></i>
              </span>
    </div>
    <div class="flex-1 pl-1">
      <span class="h2">{{errorMessage.header}}</span>
      <br>
      {{errorMessage.message}}
    </div>
  </div>
</div>
