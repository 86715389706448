import {Component, OnInit} from '@angular/core';
import {Folder, MeterAccount} from '@app/core/model/Folder';
import {FoldersService} from '@app/core/services/folders.service';
import {first} from 'rxjs/operators';
import {AppService} from '@app/core/services/app.service';

@Component({
  selector: 'app-folders',
  templateUrl: './folder.component.html'
})
export class FolderComponent implements OnInit {
  loading = false;

  parentFolder: Folder;
  selectedMeterAccount: MeterAccount;

  constructor(private foldersService: FoldersService, private  appService: AppService) {
  }

  onSelected(meterAccount: MeterAccount): void {
    this.selectedMeterAccount = meterAccount;
    this.foldersService.updateSelectedMeter(meterAccount);
  }

  ngOnInit(): void {
    this.loading = true;

    this.foldersService.getFolders()
      .pipe(first())
      .subscribe(meterFolder => {
        this.loading = false;

        this.parentFolder = meterFolder.folder;
        this.selectedMeterAccount = meterFolder.selectedMeterAccount;

        this.appService.updateSelectedMeter(meterFolder.selectedMeterAccount);
      });
  }
}
