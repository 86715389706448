// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.onlinemeter.ts`.
// The list of file replacements can be found in `angular.json`.



const onlineMeterIsak = {
  production: false,
  apiUrl: 'https://api2.onlinemeters.co.za/api',
  brandName: 'Online Meters',
  brandUrl: 'https://onlinebilling.co.za',
  showLoginBackgroundVideo: true,
  testUserName: '',
  testPassword: ''
};

const onlineMeterLocal = {
  production: false,
  apiUrl: 'http://localhost:8080/api',
  brandName: 'Online Meters',
  brandUrl: 'https://onlinebilling.co.za',
  showLoginBackgroundVideo: true,
  testUserName: 'scadaadmin',
  testPassword: '@PnPSc4d4'
};

const smartBuilding = {
  production: false,
  apiUrl: 'http://localhost:9876/api',
  brandName: 'Smart Building Billing',
  brandUrl: 'https://smartbuilding.mobi',
  showLoginBackgroundVideo: false,
  testUserName: '',
  testPassword: ''
};


const onlineMeterDocker = {
  production: false,
  apiUrl: 'https://api.onlinemeters.co.za/api',
  brandName: 'Online Meters',
  brandUrl: 'https://onlinebilling.co.za',
  showLoginBackgroundVideo: true,
  testUserName: '',
  testPassword: ''
};

export const environment = onlineMeterDocker;


// production endpoint: 41.86.112.242
// {"username":"michelle@aesirus_com","password":"metering"}
// {"username":"bol.scadaadmin","password":"1conan2"}
