import {Color} from '@amcharts/amcharts4/core';
export class Sample {
  public type: string;
  public coloredSamples: ColoredSample[];

  constructor(type: string, coloredSamples: ColoredSample[]) {
    this.type = type;
    this.coloredSamples = coloredSamples;
  }
}

export class ColoredSample {
  public date: Date;
  public kl: number;
  public kva: number;
  public kwh: number;
  public pf: number;
  public pkw: number;
  public hexColor: Color;

  constructor(date: Date,
              kl: number,
              kva: number,
              kwh: number,
              pf: number,
              pkw: number,
              hexColor: Color) {
    this.kl = kl;
    this.date = date;
    this.kva = kva;
    this.kwh = kwh;
    this.pf = pf;
    this.pkw = pkw;
    this.hexColor = hexColor;
  }
}
