<div id="graph" class="panel">
  <div class="panel-hdr">
    <h2>Graph</h2>
    <div class="panel-toolbar">
      <button class="btn btn-panel waves-effect waves-themed" data-action="panel-fullscreen" data-toggle="tooltip"
              data-offset="0,10" data-original-title="Fullscreen"></button>
    </div>
  </div>

  <div class="panel-container show">
    <div class="panel-content">
        <div id="chart" style="width: 100%; height: 500px"></div>
      </div>
    </div>
</div>
