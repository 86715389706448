import {Injectable, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {MeterAccount} from '@app/core/model/Folder';
import {Message} from '@app/core/model/Message';
// @ts-ignore
import * as ThemeJson from '@assets/css/themes/list.json';
import {Config, Theme} from '@app/core/model/Config';
import {storageKeyMeterAccounts, storageKeySelectedMeter, storageKeyUserConfig} from '@app/core/helpers/app.const';

// Service for to communicate between the scope of application.
@Injectable({
  providedIn: 'root'
})
export class AppService {
  private themes = (ThemeJson as Theme[]);

  public userConfigSubject = new BehaviorSubject<Config>(undefined);
  public selectedMeterAccount = new BehaviorSubject<MeterAccount>(undefined);
  public message = new BehaviorSubject<Message>(undefined);

  updateSelectedMeter(meterAccount: MeterAccount): void {
    localStorage.setItem(storageKeySelectedMeter, JSON.stringify(meterAccount));
    this.selectedMeterAccount.next(meterAccount);
  }

  updateConfig(config: Config): void {
    localStorage.setItem(storageKeyUserConfig, JSON.stringify(config));
    this.userConfigSubject.next(config);
  }

  getUserConfig(): Config {
    return JSON.parse(localStorage.getItem(storageKeyUserConfig));
  }

  postMessage(header: string, message: string): void {
    this.message.next(new Message(header, message));
  }

  dismissMessage(): void {
    this.message.next(undefined);
  }

  getThemes(): Theme[] {
    return (this.themes as any).default;
  }
}
