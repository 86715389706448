import {Component, Inject, OnInit, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {AppService} from '@app/core/services/app.service';
import {Config} from '@app/core/model/Config';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  constructor(@Inject(DOCUMENT) private document, private renderer: Renderer2, private appService: AppService) {
  }

  ngOnInit(): void {
    // if don't exist, create one.....
    if (!this.appService.getUserConfig()) {
      console.log('User configuration dont exist, crate one....');
      this.appService.updateConfig(
        new Config(this.appService.getThemes()[0], '', false)
      );
    }

    // setup  theme and skin from local storage.
    this.setUpThemeAndSkin(this.appService.getUserConfig());

    // Subscribe to future changes from user.
    this.appService.userConfigSubject
      // .pipe(filter(value => value !== undefined))
      .subscribe(userConfig => {
          this.setUpThemeAndSkin(userConfig);
        }
      );
  }

  setUpThemeAndSkin(userConfig: Config): void {
    if (userConfig !== undefined) {
      console.log('Updating theme and skin');
      console.log(userConfig);

      // Update the theme....
      this.document.getElementById('mytheme').setAttribute('href', userConfig.theme.path);

      // Remove skins and add a new one.
      this.renderer.removeClass(document.body, 'mod-skin-light');
      this.renderer.removeClass(document.body, 'mod-skin-dark');
      if (userConfig.skin !== '') {
        this.renderer.addClass(document.body, userConfig.skin);
      }
    }
  }
}
