import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '@environments/environment';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class DesiredPfService {

  private endPoint = '/user/setDesirePf';

  constructor(private http: HttpClient) {
  }

  setDesiredPf(meterId: number, desiredPfValue: number): Observable<any> {
    const endpoint = `${environment.apiUrl}${this.endPoint}`;

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Origin', '*');
    return this.http.post(endpoint,
      {
        desiredPf: desiredPfValue,
        scadaMeterId: meterId
      },
      {headers});
  }
}


