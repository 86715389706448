import {Component} from '@angular/core';
import {routerTransition} from '../../utils/animations';
import {User} from '@app/core/model/User';
import {Router} from '@angular/router';
import {AuthService} from '@app/core/services/auth.service';
import {AppService} from '@app/core/services/app.service';
import {Message} from '@app/core/model/Message';
import {Config} from '@app/core/model/Config';
import {environment} from '@environments/environment';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  animations: [routerTransition]
})
export class MainLayoutComponent {
  currentUser: User;
  message: Message;
  brandName = environment.brandName;
  brandUrl = environment.brandName;
  collapsed = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private appService: AppService
  ) {
    this.authService.currentUser.subscribe(x => this.currentUser = x);
    this.appService.message.subscribe(message => this.message = message);

    const userConfig = this.appService.getUserConfig();
    if (userConfig) {
      this.collapsed = userConfig.collapsed;
    } else {
      this.collapsed = false;
    }
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['/auth/login']).then();
  }

  toggle(): void {
    this.collapsed = !this.collapsed;

    const userConfig: Config = this.appService.getUserConfig();
    userConfig.collapsed = this.collapsed;
    this.appService.updateConfig(userConfig);
  }

  getState(outlet): void {
    if (!outlet.activatedRoute) {
      return;
    }
    const ss = outlet.activatedRoute.snapshot;

    // return unique string that is used as state identifier in router animation
    return (
      outlet.activatedRouteData.state ||
      (ss.url.length
        ? ss.url[0].path
        : ss.parent.url.length
          ? ss.parent.url[0].path
          : null)
    );
  }
}
