import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {MainLayoutComponent} from './shared/layout/main/main-layout.component';
import {AuthLayoutComponent} from './shared/layout/auth/auth-layout.component';
import {AuthGuard} from '@app/core/helpers';
import {TYPE_BASELINE_GRAPH, TYPE_DASHBOARD, TYPE_PROVISIONAL_BILL, TYPE_REPORT} from '@app/core/helpers/type.const';

const routes: Routes = [
  {
    path: 'auth',
    component: AuthLayoutComponent,
    loadChildren: () => import('@app/features/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    component: MainLayoutComponent,
    data: {pageTitle: 'Dashboard'},
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('@app/features/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {type: TYPE_DASHBOARD}
      },
      {
        path: 'dashboard',
        loadChildren: () => import('@app/features/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {type: TYPE_DASHBOARD}
      },
      {
        path: 'baseline-graph',
        loadChildren: () => import('@app/features/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {type: TYPE_BASELINE_GRAPH}
      },
      {
        path: 'provisional-bill',
        loadChildren: () => import('@app/features/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {type: TYPE_PROVISIONAL_BILL}
      },
      {
        path: 'report',
        loadChildren: () => import('@app/features/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {type: TYPE_REPORT}
      },
      {
        path: 'phasor-diagram',
        loadChildren: () => import('@app/features/phasor-diagram/phasor-diagram.module').then(m => m.PhasorDiagramModule),
        data: {pageTitle: 'Phasor Diagram'}
      },
      {
        path: 'account-profile',
        loadChildren: () => import('@app/features/account-profile/account-profile.module').then(m => m.AccountProfileModule),
        data: {pageTitle: 'Account Profile'}
      },
      {
        path: '**', redirectTo: 'dashboard'
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: false})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
