import {Component, Input} from '@angular/core';
import {ErrorMessage} from '@app/core/model/ErorrMessage';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html'
})
export class ErrorMessageComponent {

  @Input()
  errorMessage: ErrorMessage;
}
