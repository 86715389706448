import {Component} from '@angular/core';
import {AuthService} from '@app/core/services/auth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent {

  public user;

  constructor(
    private authService: AuthService
  ) {
  }

  logout(): void {
    this.authService.logout();
  }
}
