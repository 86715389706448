<div>
  <a href="#" data-toggle="dropdown" title="Settings"
     class="header-icon d-flex align-items-center justify-content-center ml-2">
    <i class="fal fa-cogs"></i>
  </a>
  <div class="dropdown-menu dropdown-menu-animated dropdown-lg">
    <div class="dropdown-divider m-0"></div>
    <a href="#" class="dropdown-item" data-action="app-fullscreen">
      <span data-i18n="drpdwn.fullscreen">Fullscreen</span>
      <i class="float-right text-muted fw-n">F11</i>
    </a>

    <div class="dropdown-divider m-0"></div>
    <div class="modal-body p-0">
      <div class="settings-panel">
        <div class="mt-4 d-table w-100 pl-5 pr-3">
          <div class="d-table-cell align-middle">
            <h5 class="p-0 pr-2 d-flex">
              Theme colors
            </h5>
          </div>
        </div>
        <div class="expanded theme-colors pl-5 pr-3">
          <a *ngFor="let theme of themes"
             class="btn btn-sm btn-icon rounded-circle" href="#" id="{{theme.id}}"
             (click)="updateTheme(theme)"
             style="background-color:{{theme.color}};">
            <i class="fal"></i>
          </a>
        </div>
        <div class="dropdown-divider m-0"></div>
        <div class="mt-4 d-table w-100 pl-5 pr-3">
          <div class="d-table-cell align-middle">
            <h5 class="p-0 pr-2 d-flex">
              Theme Modes (beta)
            </h5>
          </div>
        </div>
        <div class="pl-5 pr-3 py-3">
          <div class="row no-gutters">
            <div class="col-4 pr-2 text-center" (click)="updateSkin('')">
              <div class="d-flex bg-white border border-primary rounded overflow-hidden text-success js-waves-on"
                   style="height: 80px">
                <div class="bg-primary-600 bg-primary-gradient px-2 pt-0 border-right border-primary"></div>
                <div class="d-flex flex-column flex-1">
                  <div class="bg-white border-bottom border-primary py-1"></div>
                  <div class="bg-faded flex-1 pt-3 pb-3 px-2">
                    <div class="py-3"></div>
                  </div>
                </div>
              </div>
              Default
            </div>
            <div class="col-4 px-1 text-center" (click)="updateSkin('mod-skin-light')">
              <div class="d-flex bg-white border border-secondary rounded overflow-hidden text-success js-waves-on"
                   style="height: 80px">
                <div class="bg-white px-2 pt-0 border-right border-"></div>
                <div class="d-flex flex-column flex-1">
                  <div class="bg-white border-bottom border- py-1"></div>
                  <div class="bg-white flex-1 pt-3 pb-3 px-2">
                    <div class="py-3"></div>
                  </div>
                </div>
              </div>
              Light
            </div>
            <div class="col-4 pl-2 text-center" (click)="updateSkin('mod-skin-dark')">
              <div id="skin-dark"
                   class="d-flex bg-white border border-dark rounded overflow-hidden text-success"
                   data-original-title="Dark Mode" style="height: 80px">
                <div class="bg-fusion-500 px-2 pt-0 border-right"></div>
                <div class="d-flex flex-column flex-1">
                  <div class="bg-fusion-600 border-bottom py-1"></div>
                  <div class="bg-fusion-300 flex-1 pt-3 pb-3 px-2">
                    <div class="py-3 opacity-30"></div>
                  </div>
                </div>
              </div>
              Dark
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
