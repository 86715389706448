<div class="page-wrapper">
  <div class="page-inner">
    <aside class="page-sidebar" [ngClass]="collapsed ? 'd-none' : ''">
      <div>
        <a href="#">
          <img style="width: 100%; height: auto;" alt="logo" src="https://{{currentUser.domain}}.pnpscada.com/banner">
        </a>
      </div>

      <div class="info-card">
        <img src="assets/img/demo/avatars/avatar-m.png" class="profile-image rounded-circle"
             alt="Dr. Codex Lantern">
        <div class="info-card-text">
          <a href="#" class="d-flex align-items-center text-white">
            <span class="text-truncate text-truncate-sm d-inline-block">{{currentUser.username}}</span>
          </a>
          <span class="d-inline-block text-truncate text-truncate-sm">Domain: {{currentUser.domain}}</span>
        </div>
        <img src="assets/img/card-backgrounds/cover-2-lg.png" class="cover" alt="cover">
      </div>

      <nav id="js-primary-nav" class="primary-nav" role="navigation">
        <ul id="js-nav-menu" class="nav-menu">
          <li class="nav-title">Menu</li>

          <li routerLinkActive="active">
            <a routerLink="/dashboard" title="Dashboard"><i class="fal fa-analytics"></i>
              <span class="nav-link-text">Dashboard</span>
            </a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/baseline-graph" title="Baseline Graph"><i class="fal fa-chart-bar"></i>
              <span class="nav-link-text">Baseline Graph</span>
            </a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/phasor-diagram" title="Phasor Diagram"><i class="fal fa-compass"></i>
              <span class="nav-link-text">Phasor Diagram</span>
            </a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/provisional-bill" title="Provisional Bill"><i class="fal fa-money-bill"></i>
              <span class="nav-link-text">Provisional Bill</span>
            </a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/account-profile" title="Account Profile"><i class="fal fa-user"></i>
              <span class="nav-link-text">Account Profile</span>
            </a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="/report" title="Report"><i class="fal fa-chart-line"></i>
              <span class="nav-link-text">Report</span>
            </a>
          </li>

          <li>
            <a (click)="logout()" class="btn" title="Logout"><i class="fal fa-sign-in"></i>
              <span class="nav-link-text">Logout</span>
            </a>
          </li>
        </ul>
      </nav>
    </aside>

    <div class="page-content-wrapper">
      <header class="page-header" role="banner">

        <a class="header-btn btn" title="Hide Navigation" (click)="toggle()"><i class="ni ni-menu"></i></a>

        <app-folders></app-folders>

        <div class="ml-auto d-flex">

          <a href="#" data-action="app-print" title="Print"
             class="header-icon d-flex align-items-center justify-content-center ml-2">
            <i class="fal fa-print"></i>
          </a>

          <app-settings></app-settings>

          <app-logout></app-logout>
        </div>
      </header>

      <!-- the #js-page-content id is needed for some plugins to initialize -->
      <main id="js-page-content" role="main" class="page-content">

        <!-- Alerts/Message/etc... -->
        <div class="alert alert-primary" *ngIf="message">
          <div class="d-flex flex-start w-100">
            <div class="mr-2 hidden-md-down">
              <span class="icon-stack icon-stack-lg">
                  <i class="base base-6 icon-stack-3x opacity-100 color-primary-500"></i>
                  <i
                    class="base base-10 icon-stack-2x opacity-100 color-primary-300 fa-flip-vertical"></i>
                  <i class="ni ni-arrow-right icon-stack-1x opacity-100 color-white"></i>
              </span>
            </div>
            <div class="d-flex flex-fill">
              <div class="flex-fill">
                <span class="h5">{{message.header}}</span>
                <p>{{message.description}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="hidden-logo">
            <img style="width: 140px; height: auto;" alt="logo" src="https://{{currentUser.domain}}.pnpscada.com/banner">
        </div>

        <!-- Your main content goes below here: -->
        <div id="main" role="main" [@routerTransition]="getState(o)">
          <router-outlet #o="outlet"></router-outlet>
        </div>

      </main>

      <div class="page-content-overlay" data-action="toggle" data-class="mobile-nav-on"></div>

      <!--Footer-->
      <footer class="page-footer" role="contentinfo">
        <div class="d-flex align-items-center flex-1 text-muted">
                    <span class="hidden-md-down fw-700">2020 ©
                        <a href='{{brandUrl}}'
                           class='text-primary fw-500'
                           title='{{brandName}}'
                           target='_blank'>{{brandName}}</a>
                    </span>
        </div>
        <div>
          <ul class="list-table m-0">
            <li><a href="#" class="text-secondary fw-700">About</a></li>
            <li class="pl-3">
              <a href="#" class="text-secondary fw-700">License</a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  </div>
</div>

<script src="assets/js/vendors.bundle.js"></script>
<script src="assets/js/app.bundle.js"></script>
