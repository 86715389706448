export class Config {
  theme: Theme;
  skin: string;
  collapsed: boolean;

  constructor(theme: Theme, mode: string, collapsed: boolean) {
    this.theme = theme;
    this.skin = mode;
    this.collapsed = collapsed;
  }
}

export class Theme {
  id: number;
  path: string;
  color: string;

  constructor(id: number, path: string, color: string) {
    this.id = id;
    this.path = path;
    this.color = color;
  }
}
